import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Privacy() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="md">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h5" variant="h5">
        CM Software
        </Typography>
        <Typography component="h1" variant="h5">
          Privacy Policy
        </Typography>
        <Container maxWidth="md" component="main">
<p></p>
<p>Effective as of July 16, 2020</p>
<p></p>
<p>CM Software LLC (&ldquo;CM Software&rdquo;) takes data privacy seriously. This privacy policy explains who we are, how we collect, share and use Personal Information, and how you can exercise your privacy rights.</p>
<p>We recommend that you read this privacy policy in full to ensure you are fully informed. However, to make it easier for you to review the parts of this privacy policy that apply to you, we have divided up the document into sections that are specifically applicable to Members (Section 2), and Visitors(Section 3). Sections 1 and 4 are applicable to everyone.</p>
<p>If you have any questions or concerns about our use of your Personal Information, then please contact us using the contact details provided at the end of Section 5.</p>
<p>To the extent we provide you with notice of different or additional privacy policies, those policies will govern such interactions.</p>
<p></p>
<p>1. The Basics</p>
<p>A. About Us</p>
<p>CM Software licenses software used to conduct cybersecurity risk assessment &amp; planning (the &ldquo;Service&rdquo;). CM Software is operated by The Muir Group LLC, a company headquartered in the Commonwealth of Virginia in the United States ("we," "us," "our," and "CM Software").</p>
<p>Our Service enables our Members to, among other things, conduct cybersecurity risk assessments against cybersecurity standards and frameworks, create Plans of Action &amp; Milestones, and manage implementations through a governance calendar. Find out more about our Service here. (Link to T&amp;C)</p>
<p>B. Key Terms:</p>
<p>In this privacy policy, these terms have the following meanings:</p>
<p>"Affiliate" means an entity that directly or indirectly Controls, is Controlled by or is under common Control with an entity.</p>
<p>"Control" means an ownership, voting or similar interest representing fifty percent (50%) or more of the total interests then outstanding of the entity in question. The term "Controlled" shall be construed accordingly.</p>
<p>"CM Software Site(s)" means a website hosting one of the CM Software applications.</p>
<p>"Member" means any person or entity that is registered with us to use the Service, including, but not limited to, licensees of the Service.</p>
<p>"Personal Information" means any information that identifies or can be used to identify an individual directly or indirectly. Examples of Personal Information include, but are not limited to, first and last name, date of birth, email address, gender, occupation, or other demographic information.</p>
<p>&ldquo;Risk information&rdquo; means any information that identifies the status of cybersecurity controls, notes about the implementation of those controls, plans developed concerning those controls, performance measurements, and governance information.</p>
<p>&ldquo;Service&rdquo; has the meaning given to it above.</p>
<p>"Visitor" means, depending on the context, any person who visits any of our CM Software Sites, offices, or otherwise engages with us at our events or in connection with our marketing or recruitment activities.</p>
<p>"You" and "your" means, depending on the context, either a Member, a Contact, or a Visitor.</p>
<p></p>
<p>2. Privacy for Members</p>
<p>This section applies to the Personal Information we collect and process from a Member or potential Member through the provision of the Service. If you are not a Member, the Visitors section of this policy may be more applicable to you and your data. In this section, "you" and "your" refer to Members and potential Members.</p>
<p>A. Information We Collect</p>
<p>The Personal Information that we collect depends on the context of your interactions with CM Software, your CM Software account settings, the products and features you use, your location, and applicable law. However, the Personal Information we collect broadly falls into the following categories:</p>
<p>(i) Information you provide to us: You (or your organization) may provide certain Personal Information to us when you sign up for a CM Software account and use the Service, consult with our customer service team, send us an email, integrate the Service with another website or service (for example, when you choose to connect your e-commerce account with CM Software), or communicate with us in any other way.</p>
<p>This information may include:</p>
<p>&middot; Business contact information (such as your name, job title, organization, location, phone number, email address, and country);</p>
<p>&middot; Marketing information (such as your contact preferences);</p>
<p>&middot; Account log-in credentials (such as your email address or username and password when you sign up for an account with us);</p>
<p>&middot; Troubleshooting and support data (which is data you provide or we otherwise collect in connection with support queries we receive from you. This may include contact or authentication data, the content of your chats and other communications with us, and the product or service you are using related to your help inquiry); and</p>
<p>&middot; Payment information (including your credit card numbers and associated identifiers and billing address).</p>
<p>You also provide the Service with Risk Information. depends on the context of your interactions with CM Software, your CM Software account settings, the products and features you use, your location, and applicable law. However, the Risk Information we collect broadly falls into the following categories:</p>
<p>(i) Information you provide to us: You (or your organization) may provide certain Risk Information to us when you sign up for a CM Software account and use the Service, consult with our customer service team, send us an email, integrate the Service with another website or service (for example, when you choose to connect your e-commerce account with CM Software), or communicate with us in any other way.</p>
<p>This information may include:</p>
<p>&middot; The implementation status of cybersecurity frameworks and controls available in the Service</p>
<p>&middot; Notes and explanations that describe information explaining the implementation of those controls,</p>
<p>&middot; Plans of Action &amp; Milestones (POA&amp;Ms) developed in response to the implementation statuses of cybersecurity controls,</p>
<p>&middot; Dashboards and performance measurements concerning the implementation status of cybersecurity controls, and</p>
<p>&middot; Governance information, including, but not limited to, dates and actions to be taken in response to POA&amp;Ms.</p>
<p>(ii) Information we collect automatically: When you use the Service, we may automatically collect or receive certain information about your device and usage of the Service (collectively &ldquo;Service Usage Data&rdquo;). In some (but not all) countries, including countries in the European Economic Area (&ldquo;EEA&rdquo;), this information is considered Personal Information under applicable data protection laws. We use cookies and other tracking technologies to collect some of this information. For further information, please review the section below and our Cookie Statement available here.</p>
<p>Service Usage Data may include:</p>
<p>&middot; Device information: We collect information about the device and applications you use to access the Service, such as your IP address, your operating system, your browser ID, and other information about your system and connection.</p>
<p>&middot; Log data: Our web servers keep log files that record data each time a device accesses those servers and the nature of each access, including originating IP addresses and your activity in the Service (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take (for example, which features you used)), device event information (such as system activity, error reports (sometimes called &lsquo;crash dumps&rsquo;)), and hardware settings. We may also access metadata and other information associated with files that you upload into our Service.</p>
<p>&middot; Usage data: We collect usage data about you whenever you interact with our Service, which may include the dates and times you access the Service and your browsing activities (such as what portions of the Service you used). We also collect information regarding the performance of the Service, including metrics related to the deliverability of emails and other communications you send through the Service. This information allows us to improve the content and operation of the Service, and facilitate research and analysis of the Service.</p>
<p>(iii) Information we collect from other sources: From time to time, we may obtain information about you from third-party sources, such as public databases, social media platforms, third-party data providers, and our joint marketing partners.</p>
<p>Examples of the information we receive from other sources include demographic information (such as age and gender), device information (such as IP addresses), location (such as city and state), and online behavioral data (such as information about your use of social media websites, page view information and search results and links). We use this information, alone or in combination with other Personal Information we collect, to enhance our ability to provide relevant marketing and content to you and to develop and provide you with more relevant products, features, and service.</p>
<p>B. Use of Personal Information</p>
<p>We may use the Personal Information we collect or receive through the Service (alone or in combination with other data we source) for the purposes and on the legal bases identified below:</p>
<p>&middot; To bill and collect money owed to us by you to perform our contract with you for the use of the Service or where we have not entered into a contract with you, in accordance with our legitimate interests to operate and administer our Service. This includes sending you emails, invoices, receipts, notices of delinquency, and alerting you if we need a different credit card number. We use third parties for secure credit card transaction processing, and those third parties collect billing information to process your orders and credit card payments. To learn more about the steps we take to safeguard that data, see the "Our Security" section of this privacy policy.</p>
<p>&middot; To send you system alert messages in reliance on our legitimate interests in administering the Service and providing certain features. For example, we may inform you about temporary or permanent changes to our Service, such as planned outages, or send you account, security or compliance notifications, such as new features, version updates, releases, abuse warnings, and changes to this privacy policy.</p>
<p>&middot; To communicate with you about your account and provide customer support to perform our contract with you for the use of the Service or where we have not entered into a contract with you, in reliance on our legitimate interests in administering and supporting our Service.</p>
<p>&middot; To enforce compliance with our Standard Terms of Use and applicable law, and to protect the rights and safety of our Members in reliance on our legitimate interest to protect against misuse or abuse of our Service and to pursue remedies available. This may include developing tools and algorithms that help us prevent violations. For example, sometimes we review the content our Members send or display to ensure it complies with our Standard Terms of Use. To improve that process, we have software that helps us find content that may violate our Standard Terms of Use. We may or our third-party service provider may also review content that our Members send or display. This benefits all Members who comply with our Standard Terms of Use because it reduces abuse and helps us maintain a reliable platform. Please do not use CM Software to send or display confidential information.</p>
<p>&middot; To meet legal requirements, including complying with court orders, valid discovery requests, valid subpoenas, and other appropriate legal mechanisms.</p>
<p>&middot; To provide information to representatives and advisors, including attorneys and accountants, to help us comply with legal, accounting, or security requirements in reliance on our legitimate interests.</p>
<p>&middot; To prosecute and defend a court, arbitration, or similar legal proceeding.</p>
<p>&middot; To respond to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
<p>&middot; To provide, support and improve the Service to perform our contract with you for the use of the Service or where we have not entered into a contract with you, in reliance on our legitimate interests in administering and improving the Service</p>
<p>and providing certain features. For example, this may include sharing your information with third parties in order to provide and support our Service or to make certain features of the Service available to you. When we share your Personal Information with third parties, we take steps to protect your information in a manner that is consistent with our obligations under applicable privacy laws. For further information about how we share your information, refer to Section 5 below.</p>
<p>&middot; To provide suggestions to you and to provide tailored features within our Service that optimize and personalize your experience in reliance on our legitimate interests in administering the Service and providing certain features. This includes adding features that compare Members&rsquo; email campaigns, using data to suggest other publishers your Contacts may be interested in, or using data to recommend products or services that you may be interested in or that may be relevant to you or your Contacts. Some of these suggestions are generated through analysis of the data used in our data analytics projects, as described below.</p>
<p>&middot; To perform data analytics projects in reliance on our legitimate business interests in improving and enhancing our products and services for our Members. Our data analytics projects use data from CM Software accounts to provide and improve the Service. As always, we take the privacy of Personal Information seriously, and will continue to implement appropriate safeguards to protect this Personal Information from misuse or unauthorized disclosure.</p>
<p>&middot; To personalize the Service, content and advertisements we serve to you in reliance on our legitimate interests in supporting our marketing activities and providing certain features within the Service. We may use your Personal Information to serve you specifically, such as to deliver marketing information, product recommendations and non-transactional communications (e.g., email, telemarketing calls, SMS, or push notifications) about us, in accordance with your marketing preferences and this privacy policy.</p>
<p>C. Use of Risk Information</p>
<p>At this time, CM Software does not use the Risk Information that you provide us for any purposes. CM Software reserves the right to anonymize Risk Information to understand aggregates and trends in cybersecurity compliance so that CM Software can develop better Services in the future.</p>
<p>D. Cookies and Tracking Technologies</p>
<p>We and our third-party partners may use various technologies to collect and store Service Usage Data when you use our Service (as discussed above), and this may include using cookies and similar tracking technologies, such as pixels, web beacons.</p>
<p>Our use of cookies and other tracking technologies is discussed in more detail in our Cookie Statement available here.</p>
<p>E. Your Data Protection Rights</p>
<p>Depending on the country in which you reside, you may have the following data protection rights:</p>
<p>&middot; To access; correct; update; port; delete; restrict; or object to our processing of your Personal Information.</p>
<p>&middot; To access; correct; update; delete; restrict; or object to the use of the Risk Information you have provided to the Service.</p>
<p>&middot; You can manage your individual account and profile settings within the dashboard provided through the CM Software platform, or you may contact us directly. CM Software takes reasonable steps to ensure that the data we collect is reliable for its intended use, accurate, complete and up to date.</p>
<p>&middot; The right to complain to a data protection authority about the collection and use of Personal Information. For more information, please contact your local data protection authority. Contact details for data protection authorities in the EEA and UK are available here and Switzerland are available here.</p>
<p>&middot; Similarly, if Personal Information is collected or processed on the basis of consent, the data subject can withdraw their consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your Personal Information conducted in reliance on lawful processing grounds other than consent.</p>
<p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection law. We may ask you to verify your identity in order to help us respond efficiently to your request. If we receive a request from one of your Contacts, we will either direct the Contact to reach out to you, or, if appropriate, we may respond directly to their request.</p>
<p></p>
<p>3. Privacy for Visitors</p>
<p>This section applies to Personal Information that we collect and process when you visit the CM Software Sites, and in the usual course of our business, such as in connection with our recruitment, events, sales and marketing activities or when you visit our offices. In this section, "you" and "your" refer to Visitors.</p>
<p>A. Information We Collect</p>
<p>(i) Information you provide to us on the CM Software Sites or otherwise: Our CM Software Sites offer various ways to contact us, such as through form submissions, email or phone, to inquire about our company and Service. For example, we may ask you to provide certain Personal Information when you express an interest in obtaining information about us or our Service, take part in surveys, subscribe to marketing, apply for a role with CM Software, or otherwise contact us. We may also collect Personal Information from you via a phone call with one of our sales representatives. You may choose to provide additional information when you communicate with us or otherwise interact with us, and we may keep copies of any such communications for our records.</p>
<p>The Personal Information we collect may include:</p>
<p>&middot; Business contact information (such as your name, phone number, email address and country);</p>
<p>&middot; Professional information (such as your job title, institution or company);</p>
<p>&middot; Nature of your communication;</p>
<p>&middot; Marketing information (such as your contact preferences); and</p>
<p>&middot; Any information you choose to provide to us when completing any &lsquo;free text&rsquo; boxes in our forms.</p>
<p>(ii) Information we collect automatically through the CM Software Sites: When you visit our CM Software Sites or interact with our emails, we use cookies and similar</p>
<p>technologies such as pixels or web beacons, alone or in conjunction with cookies, to collect certain information automatically from your browser or device. In some countries, including countries in the EEA, this information may be considered Personal Information under applicable data protection laws. Our use of cookies and other tracking technologies is discussed more below, and in more detail in our Cookie Statement available here.</p>
<p>The information we collect automatically includes:</p>
<p>&middot; Device information: such as your IP address, your browser, device information, unique device identifiers, mobile network information, request information (speed, frequency, the site from which you linked to us (&ldquo;referring page&rdquo;), the name of the website you choose to visit immediately after ours (called &ldquo;exit page&rdquo;), information about other websites you have recently visited and the web browser you used (software used to browse the internet) including its type and language)</p>
<p>&middot; Usage data: such as information about how you interact with our emails, CM Software Sites, and other websites (such as the pages and files viewed, searches, operating system and system configuration information and date/time stamps associated with your usage).</p>
<p>B. Use of Personal Information</p>
<p>We may use the information we collect through our CM Software Sites and in connection with our events and marketing activities (alone or in combination with other data we collect) for a range of reasons in reliance on our legitimate interests, including:</p>
<p>&middot; To provide, operate, optimize, and maintain the CM Software Sites.</p>
<p>&middot; To send you marketing information, product recommendations and non-transactional communications (e.g., email, telemarketing calls, SMS, or push notifications) about us, in accordance with your marketing preferences, including information about our products, services, promotions or events as necessary for our legitimate interest in conducting direct marketing or to the extent you have provided your prior consent.</p>
<p>&middot; For recruitment purposes if you have applied for a role with CM Software.</p>
<p>&middot; To respond to your online inquiries and requests, and to provide you with information and access to resources or services that you have requested from us.</p>
<p>&middot; To manage the CM Software Sites and system administration and security.</p>
<p>&middot; To manage event registrations and attendance, including sending related communications to you.</p>
<p>&middot; To register visitors to our offices for security reasons and to manage non-disclosure agreements that visitors may be required to sign.</p>
<p>&middot; To improve the navigation and content of the CM Software Sites.</p>
<p>&middot; To identify any server problems or other IT or network issues.</p>
<p>&middot; To process transactions and to set up online accounts.</p>
<p>&middot; To compile aggregated statistics about site usage and to better understand the preferences of our Visitors.</p>
<p>&middot; To help us provide, improve and personalize our marketing activities.</p>
<p>&middot; To facilitate the security and continued proper functioning of the CM Software Sites.</p>
<p>&middot; To carry out research and development to improve our CM Software Sites, products and services.</p>
<p>&middot; To conduct marketing research, advertise to you, provide personalized information about us on and off our CM Software Sites, and to provide other personalized content based on your activities and interests to the extent necessary for our legitimate interests in supporting our marketing activities or advertising our Service or instances where we seek your consent.</p>
<p>&middot; To carry out other legitimate business purposes, as well as other lawful purposes, such as data analysis, fraud monitoring and prevention, identifying usage trends and expanding our business activities in reliance on our legitimate interests.</p>
<p>&middot; To cooperate with public and government authorities, courts or regulators in accordance with our legal obligations under applicable laws to the extent this requires the processing or disclosure of Personal Information to protect our rights or is necessary for our legitimate interest in protecting against misuse or abuse of our CM Software Sites and Service, protecting personal property or safety, pursuing remedies available to us and limiting our damages, complying with judicial proceedings, court orders or legal processes, or responding to lawful requests.</p>
<p>C. Public Information and Third-Party Websites</p>
<p>&middot; Blog. We have public blogs on the CM Software Sites. Any information you include in a comment on our blog may be read, collected, and used by anyone. If your Personal Information appears on our blogs and you want it removed, contact us here. If we are unable to remove your information, we will tell you why.</p>
<p>&middot; Social media platforms and widgets. The CM Software Sites may include social media features, such as the Facebook Like button. These features may collect information about your IP address and which page you are visiting on our CM Software Site, and they may set a cookie to make sure the feature functions properly. Social media features and widgets are either hosted by a third party or hosted directly on our CM Software Site. We also may maintain presences on social media platforms, including Facebook, Twitter, and Instagram. Any information, communications, or materials you submit to us via a social media platform is done at your own risk without any expectation of privacy. We cannot control the actions of other users of these platforms or the actions of the platforms themselves. Your interactions with those features and platforms are governed by the privacy policies of the companies that provide them.</p>
<p>&middot; Links to third-party websites. The CM Software Sites include links to other websites, whose privacy practices may be different from ours. If you submit Personal Information to any of those sites, your information is governed by their privacy policies. We encourage you to carefully read the privacy policy of any website you visit.</p>
<p>&middot; Contests and sweepstakes. We may, from time to time, offer surveys, contests, sweepstakes, or other promotions on the CM Software Sites or through social media (collectively, "Promotions"). Participation in our Promotions is completely voluntary. Information requested for entry may include Personal Information such as your name, address, date of birth, phone number, email address, username,</p>
<p>and similar details. We use the information you provide to administer our Promotions. We may also, unless prohibited by the Promotion&rsquo;s rules or law, use the information provided to communicate with you, or other people you select, about our Service. We may share this information with our affiliates and other organizations or service providers in line with this privacy policy and the rules posted for our Promotions.</p>
<p>D. Cookies and Tracking Technologies</p>
<p>We use cookies and similar tracking technologies to collect and use Personal Information about you, including to serve interest-based advertising. For further information about the types of cookies and tracking technologies we use, why, and how you can control them, please see our Cookie Statement available here.</p>
<p>E. Other Data Protection Rights</p>
<p>Depending on the country in which you reside, you may have the following data protection rights:</p>
<p>&middot; To access; correct; update; port; delete; restrict or object to our processing of your Personal Information. You can exercise these rights by contacting CM Software directly.</p>
<p>&middot; You may also have the right to complain to a data protection authority about our collection and use of your Personal Information. For more information, please contact your local data protection authority. Contact details for data protection authorities in the EEA are available here.</p>
<p>&middot; Similarly, if we have collected and processed your Personal Information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your Personal Information conducted in reliance on lawful processing grounds other than consent. You can also contact us at any time to update your marketing preferences (see Section 5. General Information, C. Your Choices and Opt-Outs below).</p>
<p>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. We may ask you to verify your identity in order to help us respond efficiently to your request.</p>
<p></p>
<p>4. General Information</p>
<p>A. How We Share Information</p>
<p>We may share and disclose your Personal Information with our Affiliates and to the following types of third parties for the purposes described in this privacy policy (for purposes of this section, "you" and "your" refer to Members and Visitors unless otherwise indicated).</p>
<p>(i) Our service providers: Sometimes, we share your information with our third-party service providers working on our behalf for the purposes described in this privacy policy. For example, companies we&rsquo;ve hired to help us provide and support our Service or assist in protecting and securing our systems and services and other business-related functions.</p>
<p>Other examples include analyzing data, hosting data, engaging technical support for our Service, processing payments, and delivering content.</p>
<p>(ii) Any competent law enforcement body, regulatory body, government agency, court or other third party where we believe disclosure is necessary (a) as a matter of applicable law or regulation, (b) to exercise, establish, or defend our legal rights, or (c) to protect your vital interests or those of any other person.</p>
<p>(iii) A potential buyer (and its agents and advisors) in the case of a sale, merger, consolidation, liquidation, reorganization, or acquisition. In that event, any acquirer will be subject to our obligations under this privacy policy, including your rights to access and choice. We will notify you of the change either by sending you an email or posting a notice on our CM Software Site.</p>
<p>(iv) Any other person with your consent.</p>
<p>B. Legal Basis for Processing Personal Information (EEA and UK Persons Only)</p>
<p>If you are located in the EEA or UK, our legal basis for collecting and using the Personal Information described above will depend on the Personal Information concerned and the specific context in which we collect it.</p>
<p>However, we will normally collect and use Personal Information from you where the processing is in our legitimate interests and not overridden by your data-protection interests or fundamental rights and freedoms. Our legitimate interests are described in more detail in this privacy policy in the sections above titled &ldquo;Use of Personal Information&rdquo;, but they typically include improving, maintaining, providing, and enhancing our technology, products, and services; ensuring the security of the Service and our CM Software Sites; and supporting our marketing activities.</p>
<p>If you are a Member, we may need the Personal Information to perform a contract with you. In some limited cases, we may also have a legal obligation to collect Personal Information from you. If we ask you to provide Personal Information to comply with a legal requirement or to perform a contract with you, we will make this clear at the relevant time and advise you whether the provision of your Personal Information is mandatory or not, as well as of the possible consequences if you do not provide your Personal Information.</p>
<p>Where required by law, we will collect Personal Information only where we have your consent to do so.</p>
<p>If you have questions or need further information concerning the legal basis on which we collect and use your Personal Information, please contact us using the contact details provided in the "Questions and Concerns" section below.</p>
<p>C. Your Choices and Opt-Outs</p>
<p>Members and Visitors who have opted into our marketing emails can opt out of receiving marketing emails from us at any time by clicking the "unsubscribe" link at the bottom of our marketing messages.</p>
<p>Also, all opt-out requests can be made by emailing us using the contact details provided in the "Questions and Concerns" section below. Please note that some communications (such as service messages, account notifications, billing information) are considered transactional and necessary for account management, and Members cannot opt out of these messages unless you cancel your CM Software account.</p>
<p>D. Our Security</p>
<p>We take appropriate and reasonable technical and organizational measures designed to protect Personal Information from loss, misuse, unauthorized access, disclosure, alteration, and destruction, taking in</p>
<p>the nature of the Personal Information. For further information about our security practices, please see our Security page available here. If you have any questions about the security of your Personal Information, you may contact us directly.</p>
<p>CM Software accounts require a username and password to log in. Members must keep their username and password secure, and never disclose it to a third party. Because the information in a Member&rsquo;s CM Software account is private, account passwords are hashed, which means we cannot see a Member&rsquo;s password. We cannot resend forgotten passwords either. We will only provide Members with instructions on how to reset them.</p>
<p>E. International Transfers</p>
<p>(i) We operate in the United States</p>
<p>Our servers and offices are located in the United States, so your information may be transferred to, stored, or processed in the United States. While the data protection, privacy, and other laws of the United States might not be as comprehensive as those in your country, we take many steps to protect your privacy, including offering our Members a Data Processing Agreement available here.</p>
<p>(ii) Data transfers from Switzerland, United Kingdom, or the EEA to the United States</p>
<p>CM Software participates in and has certified its compliance with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework. We are committed to subjecting all Personal Information received from EEA member countries, United Kingdom, and Switzerland, respectively, in reliance on each Privacy Shield Framework, to each Framework&rsquo;s applicable Principles. To learn more about the Privacy Shield Frameworks, and to view our certification, visit the U.S. Department of Commerce&rsquo;s Privacy Shield website available here.</p>
<p>A list of Privacy Shield participants is maintained by the Department of Commerce and is available here.</p>
<p>CM Software is responsible for the processing of Personal Information we receive under each Privacy Shield Framework and subsequently transfer to a third party acting as an agent on our behalf. We comply with the Privacy Shield Principles for all onward transfers of Personal Information from the EEA, United Kingdom, and Switzerland, including the onward transfer liability provisions.</p>
<p>With respect to Personal Information received or transferred pursuant to the Privacy Shield Frameworks, we are subject to the regulatory enforcement powers of the U.S. Federal Trade Commission. In certain situations, we may be required to disclose Personal Information in response to lawful requests by public authorities, including to meet national security or law enforcement requirements.</p>
<p>If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact our U.S.-based third-party dispute resolution provider (free of charge to you) at https://feedback-form.truste.com/watchdog/request. Under certain conditions, more fully described on the Privacy Shield website, here, you may be entitled to invoke binding arbitration when other dispute resolution procedures have been exhausted.</p>
<p>Members located in Switzerland, United Kingdom, and the EEA are subject to our Data Processing Addendum available here, as described in our Standard Terms of Use.</p>
<p>(iii) Members, Contacts and Visitors located in Australia</p>
<p>If you are a Member, Contact or Visitor who accesses our Service in Australia, this section applies to you. We are subject to the operation of the Privacy Act 1988 ("Australian Privacy Act"). Here are the specific points you should be aware of: &middot; As stated in our Acceptable Use Policy available here, sensitive personal information is not permitted on CM Software&rsquo;s platform and Members are prohibited from importing or incorporating any sensitive personal information into their CM Software accounts or uploading any sensitive personal information to CM Software&rsquo;s servers.</p>
<p>&middot; Please note that if you do not provide us with your Personal Information or if you withdraw your consent for us to collect, use and disclose your Personal Information, we may be unable to provide the Service to you.</p>
<p>&middot; Where we collect Personal Information of our Visitors, the Personal Information we ask you to provide will be information that is reasonably necessary for, or directly related to, one or more of our functions or activities. Please see Section 3 of this privacy policy for examples of the types of Personal Information we may ask Visitors to provide.</p>
<p>&middot; Where we say we assume an obligation about Personal Information, we will also require our contractors and subcontractors to undertake a similar obligation.</p>
<p>&middot; We will not use or disclose Personal Information for the purpose of our direct marketing to you unless:</p>
<p>&middot; you have consented to receive direct marketing;</p>
<p>&middot; you would reasonably expect us to use your personal details for marketing; or</p>
<p>&middot; we believe you may be interested in the material but it is impractical for us to obtain your consent.</p>
<p>You may opt out of any marketing materials we send to you through an unsubscribe mechanism. If you have requested not to receive further direct marketing messages, we may continue to provide you with messages that are not regarded as "direct marketing" under the Australian Privacy Act, including changes to our terms, system alerts, and other information related to your account as permitted under the Australian Privacy Act and the Spam Act 2003 (Cth).</p>
<p>&middot; Our servers are located in the United States. In addition, we or our subcontractors may use cloud technology to store or process Personal Information, which may result in storage of data outside Australia. It is not practicable for us to specify in advance which country will have jurisdiction over this type of offshore activity. All of our subcontractors, however, are required to comply with the Australian Privacy Act in relation to the transfer or storage of Personal Information overseas.</p>
<p>&middot; We may also share your Personal Information outside of Australia to our business operations in other countries. While it is not practicable for us to specify in advance each country where your Personal Information may be disclosed, typically we may disclose your Personal Information to the United States, Canada and the European Union.</p>
<p>&middot; You may access the Personal Information we hold about you. If you wish to access your Personal Information, please contact us directly.</p>
<p>If you think the information we hold about you is inaccurate, out of date, incomplete, irrelevant, or misleading, we will take reasonable steps, consistent with our obligations under the Australian Privacy Act, to correct that information upon your request. If you find that the information we have is not up to date or is inaccurate or incomplete, please contact us in writing so we can update our records. We will respond to all requests for correction within a reasonable time.</p>
<p>&middot; If you are unsatisfied with our response to a privacy matter, you may consult either an independent advisor or contact the Office of the Australian Information Commissioner for additional help. We will provide our full cooperation if you pursue this course of action.</p>
<p>F. Retention of Data</p>
<p>We retain Personal Information where we have an ongoing legitimate business or legal need to do so. Our retention periods will vary depending on the type of data involved, but, generally, we'll refer to these criteria in order to determine retention period:</p>
<p>&middot; Whether we have a legal or contractual need to retain the data.</p>
<p>&middot; Whether the data is necessary to provide our Service.</p>
<p>&middot; Whether our Members have the ability to access and delete the data within their CM Software accounts.</p>
<p>&middot; Whether our Members would reasonably expect that we would retain the data until they remove it or until their CM Software accounts are closed or terminated.</p>
<p>When we have no ongoing legitimate business need to process your Personal Information, we will either delete or anonymize it or, if this is not possible (for example, because your Personal Information has been stored in backup archives), then we will securely store your Personal Information and isolate it from any further processing until deletion is possible.</p>
<p>G. California Privacy</p>
<p>The California Consumer Privacy Act (&ldquo;CCPA&rdquo;) provides consumers with specific rights regarding their Personal Information. You have the right to request that businesses subject to the CCPA (which may include our Members with whom you have a relationship) disclose certain information to you about their collection and use of your Personal Information over the past 12 months. In addition, you have the right to ask such businesses to delete Personal Information collected from you, subject to certain exceptions. If the business sells Personal Information, you have a right to opt-out of that sale. Finally, a business cannot discriminate against you for exercising a CCPA right.</p>
<p>When offering services to its Members, CM Software acts as a &ldquo;service provider&rdquo; under the CCPA and our receipt and collection of any consumer Personal Information is completed on behalf of our Members in order for us to provide the Service. Please direct any requests for access or deletion of your Personal Information under the CCPA to the Member with whom you have a direct relationship.</p>
<p>Consistent with California law, if you choose to exercise your applicable CCPA rights, we won&rsquo;t charge you different prices or provide you a different quality of services. If we ever offer a financial incentive or product enhancement that is contingent upon you providing your Personal Information, we will not do so unless the benefits to you are reasonably related to the value of the Personal Information that you provide to us.</p>
<p>H. Do not Track</p>
<p>Certain state laws require us to indicate whether we honor &ldquo;Do Not Track&rdquo; settings in your browser. CM Software adheres to the standards set out in this Privacy Policy and does not monitor or follow any Do Not Track browser requests.</p>
<p>I. Changes to this Policy</p>
<p>We may change this privacy policy at any time and from time to time. The most recent version of the privacy policy is reflected by the version date located at the top of this privacy policy. All updates and amendments are effective immediately upon notice, which we may give by any means, including, but not limited to, by posting a revised version of this privacy policy or other notice on the CM Software Sites. We encourage you to review this privacy policy often to stay informed of changes that may affect you. Our electronically or otherwise properly stored copies of this privacy policy are each deemed to be the true, complete, valid, authentic, and enforceable copy of the version of this privacy policy that was in effect on each respective date you visited the CM Software Site.</p>
<p>J. Questions &amp; Concerns</p>
<p>If you have any questions or comments, or if you have a concern about the way in which we have handled any privacy matter, please send us a message. You may also contact us by postal mail or email at:</p>
        </Container>
      </div>
    </Container>
  );
}