import axios from "axios";
import { getApiUrl } from './api';
import { LOGIN, SSOLOGIN, } from "./actionTypes";

const login = (login) => {
  return (dispatch) => {
    let data = { username: login.username, password: login.password };
    return axios.post(`${getApiUrl()}/login`, data).then((response) => {
      if (typeof (response.data) === "string") {
        window.location = response.data;
      }

      dispatch({
        type: LOGIN,
        authenticated: response.data.is_authenticated,
        password_error: response.data.password_is_authenticated === false,
        email_error: response.data.email_is_authenticated === false,
        active_error: response.data.user_is_active === false,
        status: response.data.status,
        sessionId: response.data.sessionId,
        plan: response.data.plan
      });
      return response.data;
    });
  };
};

const mfaCheck = (username) => {
  return axios.get(`${getApiUrl()}/mfa/${username}`).then((response) => {
    return response.data;
  });
}


const checkCodeTotp = (data) => {
  return axios.post(`${getApiUrl()}/totp`, data).then((response) => {
    return response.data;
  });
}


const validUser = (data) => {
  return axios.post(`${getApiUrl()}/login`, data).then((response) => {
    return response
  });
}

const startResetPassword = (data) => {
  return axios.post(`${getApiUrl()}/external`, data).then((response) => {
    if (response.data == 'An email has been sent with instructions to reset your password') {
      window.location = "/login";
    }
    return response.data
  });
}

const resetPassword = (data) => {
  return axios.post(`${getApiUrl()}/resetpassword`, data).then((response) => {
    if (response.data == 'Your password has been changed.') {
      alert('Your password has been succesfully changed.')
      window.location = "/login";
    }
    return response.data
  });
}


const ssoLogin = () => {
  return (dispatch) => {
    return axios.get(`${getApiUrl()}/sso`).then((response) => {
      if (typeof (response.data) === "string") {
        window.location = response.data;
      }
      dispatch({
        type: SSOLOGIN,
        path: response.path,
        authenticated: response.data.is_authenticated,
        error: response.data.is_authenticated === false,
        status: response.data.status,
        sessionId: response.data.sessionId,
        plan: response.data.plan
      });
      return response.data;
    });
  }
}

export {
  login,
  ssoLogin,
  mfaCheck,
  validUser,
  checkCodeTotp,
  startResetPassword,
  resetPassword
}

