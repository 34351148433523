import axios from "axios";
import { getApiUrl } from './api';
import { loadStripe } from '@stripe/stripe-js';
import { SELECT_PLAN, CREATE_USER_REQUEST, CREATE_USER_SUCCESS, ADD_USER_TO_SYS_SUCCESS, ADD_USER_TO_SYS_REQUEST } from "./actionTypes";

//const stripeKey = process.env.NODE_ENV !== 'production' && false ? 'pk_test_51GwvjgDxeNJY6mlJaTU1FcMjEMBRp4JOxtnnYbKd66omKPDmncCuku5NPu4Xu0At4eGS9yX2q9hshWDsISlgOtKl00GbRIpkDT'
//    : 'pk_live_51GwvjgDxeNJY6mlJdo67epbsO9BTFieplbWIvWEFQl316ju5C7c5Ptwr6nQtVnfrbggegkinvYJdRquhuNXxIKx900q67RoIb5';

const stripeKey = 'pk_test_51Kpym5K3FeMaWyxuhfeCmASKaSdw6NHy41fA3ldcgx1RsX1d6zJB6p5Z3dvoj9Irec0xcWDE1ne3013E3CEAIoOp00bQUCu6JL'

const stripePromise = loadStripe(stripeKey);

export const selectPlan = (plan) => {
  return (dispatch) => {
    return dispatch({ type: SELECT_PLAN, plan: plan });
  }
}

export const signUp = (data) => {
  return axios.post(`${getApiUrl()}/register`, data).then((response) => {
    let stripe_cust_id = response.data;
    return stripe_cust_id;
  });
}

export const checkout = (plan, stripe_cust_id, username) => {
  return axios.post(`${getApiUrl()}/checkout`, { plan, stripe_cust_id, username }).then((response) => {
    let sessionId = response.data;
    stripePromise.then(stripe => {
      stripe.redirectToCheckout({ "sessionId": sessionId });
    });
  });
}

// export const inviteRegister = (data) => {
//   return axios.post(`${getApiUrl()}/inviteregister`, data).then(response => {
//     return response
//   })
// }

export const fetchQrCode = async (name) => {
  const response = await axios.get(`${getApiUrl()}/totp/${name}`);
  return response.data;
}

export const postMfa = (data) => {
  return axios.post(`${getApiUrl()}/mfa`, data).then((response) => {
    return response.data;
  });
}

export const getMfa = (usr) => {
  return axios.get(`${getApiUrl()}/mfa/${usr}`).then((response) => {
    return response.data;
  });
}

export const checkCode = (data) => {
  return axios.post(`${getApiUrl()}/code`, data).then(response => {
    return response;
  });
}


export const createUser = (user) => {
  // return (dispatch) => {
  //  dispatch({ type: CREATE_USER_REQUEST });

  return axios.post(`${getApiUrl()}/usersignup`, user).then((response) => {
    // return dispatch(createUserSuccess(response.data));
    return response;
  });
  //  };
};

const createUserSuccess = (user) => {
  return {
    type: CREATE_USER_SUCCESS,
    user,
  };
};
