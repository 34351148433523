import { SELECT_PLAN, CREATE_USER_REQUEST, CREATE_USER_SUCCESS } from "../actions/actionTypes";

import initialState from "./initialState";

export default function registerReducer(state = initialState.register, action ) {
    switch (action.type) {
        case SELECT_PLAN:
            return {...state, plan:action.plan};

        case CREATE_USER_REQUEST:


        case CREATE_USER_SUCCESS: 
       
        default:
            return state;

    }
}