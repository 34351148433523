import React from 'react';
import { NavLink } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

function Nav({ routes }) {
  const useStyles = makeStyles((theme) => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    appBar: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
      flexWrap: 'wrap',
      backgroundColor: '#FFD966',
      color: '#1F3864'
    },
    toolbarTitle: {
      flexGrow: 1,
    },
    link: {
      margin: theme.spacing(1, 1.5),
    },
    heroContent: {
      padding: theme.spacing(8, 0, 6),
    },
    cardHeader: {
      backgroundColor:
        theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      marginBottom: theme.spacing(2),
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,
      marginTop: theme.spacing(8),
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
  }));
  const classes = useStyles();
  const activeRoute = (routeName) => {
    return window.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  const renderLink = (route) => {
    // Making sure the ssoregister page isn't visible
    if (route.path === "/ssoregister" || route.path === "/mfalogin" || route.path == "/reset" || route.path == "/external/:token") {
      return "";
    }
    if (route.external)
      return (
        <a className="nav-link" href={route.path} target={route.newtab ? "_blank_" : ""} key={route.name}>
          <i className={"cm-icons " + route.icon} color="danger"></i>
          <p>{route.name}</p>
        </a>
      );
    if (route.name.toLowerCase() === "login") {
      return (
        <Button component={NavLink} to={route.path} color="primary" variant="outlined" className={classes.link} key={route.name}>
          Login
        </Button>)
    }
    return (
      <Link variant="button" color="textPrimary" component={NavLink} to={route.path} className={classes.link} key={route.name}>
        {route.name}
      </Link>
    );
  };
  return (
    <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          Tempered Compliance
        </Typography>
        <nav>{routes.map((route, key) => {
          if (route.redirect) return null;
          return renderLink(route);
        })}
        </nav>
      </Toolbar>
    </AppBar>
  )
}

export default Nav;