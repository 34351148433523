import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

export default function Footer() {
    const useStyles = makeStyles((theme) => ({
      "@global": {
        ul: {
          margin: "15px 0 !important",
          padding: 0,
          listStyle: "none",
        },
      },
      appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      toolbar: {
        flexWrap: "wrap",
      },
      toolbarTitle: {
        flexGrow: 1,
      },
      link: {
        margin: theme.spacing(1, 1.5),
      },
      heroContent: {
        padding: theme.spacing(8, 0, 6),
      },
      cardHeader: {
        backgroundColor:
          theme.palette.type === "light"
            ? theme.palette.grey[200]
            : theme.palette.grey[700],
      },
      cardPricing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        marginBottom: theme.spacing(2),
      },
      footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up("sm")]: {
          paddingTop: theme.spacing(6),
          paddingBottom: theme.spacing(6),
        },
      },
      legal: {
        display: "flex",
        width: "100%",
        justifyContent: "center"
      },
      legalItem: {
        padding: "0 10px"
      }
    }));
    const classes = useStyles();

    const Copyright = () => {
        return (
          <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <Link color="inherit" href="https://www.muircybersolutions.com">
            Muir Cyber Solutions
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        );
      }
      
    const footers = [
        /*{
          title: "Company",
          description: ["Team", "History", "Contact us", "Locations"],
        },
        {
          title: "Features",
          description: [
            "Cool stuff",
            "Random feature",
            "Team feature",
            "Developer stuff",
            "Another one",
          ],
        },
        {
          title: "Resources",
          description: [
            "Resource",
            "Resource name",
            "Another resource",
            "Final resource",
          ],
        },*/
        {
          //title: "Legal",
          description: [
              {title: "Privacy policy", path:"/privacy" },
              {title: "Terms of use", path: "/terms" }
            ],
        },
      ];
  return (
    <Container maxWidth="md" component="footer" className={classes.footer}>
      <Grid container spacing={4} justify="space-evenly">
        {footers.map((footer, i) => {
          return (
            <ul key={"f"+i} className={classes.legal}>
              {footer.description.map((item, j) => (
                <li key={"d"+j} className={classes.legalItem}>
                  <Link component={NavLink} to={item.path} variant="subtitle1" color="textSecondary">
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
        )})}
      </Grid>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>);
}