import { LOGIN, SSOLOGIN } from "../actions/actionTypes";

import initialState from "./initialState";

export default function registerReducer(state = initialState.login, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        authenticated: action.authenticated,
        password_error: action.password_error,
        email_error: action.email_error,
        active_error: action.active_error,
        status: action.status,
        sessionId: action.sessionId,
        plan: action.plan,
      };
    case SSOLOGIN:
      return {
        path: "Google.com"
      }
    default:
      return state;
  }
}
