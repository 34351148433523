import React from 'react';
//import { connect } from "react-redux";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { Route, Switch, Redirect } from "react-router-dom";
import routes from "./routes/home.js";

import Nav from "./components/Nav/Nav.js";
import Footer from "./components/Footer/Footer.js"

//import logo from './logo.svg';
//import './App.css';

function App() {
    const theme = createMuiTheme({
        palette: {
            primary: { main: '#1F3864' }
        }
    });
    let navRoutes = routes.filter(r => !r.footerOnly);
    return (
        <ThemeProvider theme={theme}>
            <div className="App">
                <div>
                    <Nav routes={navRoutes} />
                </div>
                <Switch>
                    {routes.map((prop, key) => {
                        if (prop.collapse) {
                            return prop.views.map((prop2, key2) => {
                                return (
                                    <Route
                                        path={prop2.path}
                                        component={prop2.component}
                                        key={key2}
                                    />
                                );
                            });
                        }
                        if (prop.redirect)
                            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                        let Comp = prop.component;
                        return (
                            <Route path={prop.path} render={(props) => <Comp {...props} />} key={key} />
                        );
                    })}
                </Switch>
                <Footer />
            </div>
        </ThemeProvider>
    );
}

export default App;


/*
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
*/