import { useParams, useHistory } from "react-router-dom";
import { getApiUrl } from "../../actions/api";
import { getRedirectFromToken } from "../../actions/externalActions";
import axios from "axios"

export default function External() {
    let { token } = useParams();
    const history = useHistory();
    if (token) getRedirectFromToken(token).then(data => {
        if (data.redirect == "/home") {
            window.location = data.redirect
        }
        axios.post(`${getApiUrl()}` + data.redirect, { "token": token }).then(res => {
            if (res.data.redirect) window.location = res.data.redirect
        })
    })

}
