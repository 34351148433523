import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import { MuiTelInput } from 'mui-tel-input'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { NavLink } from "react-router-dom";
import { signUp, checkout, fetchQrCode } from "../../actions/registerActions";
import { getApiUrl } from '../../actions/api';
import axios from "axios"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { login, ssoLogin } from "../../actions/loginActions";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function Register() {
  const { register, handleSubmit } = useForm();
  const classes = useStyles();
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.register.plan);
  const [selectedPlan, setSelectedPlan] = useState(plan ? plan : "Basic");

  const defaultPlans = []
  const [plans, setPlans] = useState(defaultPlans)

  const [phone, setPhone] = useState({ "phone": "8000000000" })

  const registerTypes = Object.freeze({
    company: 'company',
    user: 'user',
  });
  const [registerType, setRegisterType] = useState(registerTypes.company)

  const onSubmit = data => {
    data['timezone'] = (new Date()).getTimezoneOffset();
    data['phone'] = phone["phone"]

    if (data["password"] != data["passwordRepeat"]) {
      alert("Passwords do not match")
      return
    }
    if (registerType == registerTypes.company) registerCompany(data)

    if (registerType == registerTypes.user) registerUser(data)
  }

  const registerUser = (data) => {
    axios.post(`${getApiUrl()}/usersignup`, data).then(i => {
      dispatch(login(data)).then(auth => {
        if (auth.sessionId) {
          checkout(auth.plan, auth.sessionId);
        }
      });
    })
  }

  const registerCompany = (data) => {
    signUp(data).then(sessionId => {
      fetchQrCode(data?.username)
      checkout(data.plan, sessionId, data?.username);
    });
  }
  // const proceedToCheckout = () => {
  //   checkout(userPlan, session);
  // }

  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedPlan(val);
  }

  const handlePhoneChange = (newValue) => {
    setPhone({ "phone": newValue })
  }

  useEffect(() => {
    axios.get(`${getApiUrl()}/plans`).then(i => {
      setPlans(i.data)
    })
  }, [])

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" required fullWidth>
                <Select native value={registerType} onChange={(e) => setRegisterType(e.target.value)}>
                  <option value={registerTypes.company}>Register Company</option>
                  <option value={registerTypes.user}>Register User</option>
                </Select>
              </FormControl>
            </Grid>
            {registerType == registerTypes.company &&
              <Grid item xs={12}>
                <FormControl variant="outlined" required fullWidth>
                  <Select native value={selectedPlan.id} onChange={handleChange} inputProps={{ name: "plan", id: "sub-plan", ref: register }}>
                    {plans?.map(i => {
                      return <option value={i.id}> {i.name} </option>
                    })}
                  </Select>
                </FormControl>
              </Grid>}
            {registerType == registerTypes.company &&
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="company"
                  label="Company"
                  name="company"
                  autoComplete="company"
                  inputProps={{ ref: register }}
                />
              </Grid>
            }
            {registerType == registerTypes.user &&
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="companyId"
                  label="Company Code"
                  name="companyId"
                  autoComplete="company code"
                  inputProps={{ ref: register }}
                />
              </Grid>}
            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="cage_code"
                label="Cage code"
                name="cage_code"
                autoComplete="company"
                inputProps={{ ref: register }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstname"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First name"
                inputProps={{ ref: register }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last name"
                name="lastname"
                autoComplete="lname"
                inputProps={{ ref: register }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email address"
                name="username"
                autoComplete="email"
                inputProps={{ ref: register }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                inputProps={{
                  ref: register,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passwordRepeat"
                label="Repeat password"
                type="password"
                id="passwordRepeat"
                autoComplete="passwordRepeat"
                inputProps={{
                  ref: register,
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Register
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link component={NavLink} to="/login" variant="body2">
                Already have an account? Login
              </Link>
            </Grid>
          </Grid>
        </form>
        <div>
        </div>
      </div>
    </Container>
  );
}
