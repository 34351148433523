import Home from "../views/Home/Home.js";
import Login from "../views/Login/Login.js";
import mfa from "../views/Login/Mfa.js";
import Register from "../views/Register/Register.js";
import Terms from "../views/Legal/Terms.js";
import Privacy from "../views/Legal/Privacy.js";
import SsoRegister from "../views/SsoRegistration/SsoRegister.js";
import Mfa from "../views/Login/Mfa.js";
import Reset from "../views/Login/Reset.js"
import External from "../views/External/External.js"

var routes = [
    {
        path: "/home",
        name: "Home",
        icon: "",
        component: Home
    },
    {
        path: "/register",
        name: "Register",
        icon: "",
        component: Register
    },
    {
        path: "/external/:token",
        name: "External",
        icon: "",
        component: External
    },
    {
        path: "/login",
        name: "Login",
        icon: "",
        component: Login
    },
    {
        path: "/ssoregister",
        name: "SsoRegister",
        icon: "",
        component: SsoRegister,
    },
    {
        path: "/mfalogin",
        name: "MFA Login",
        icon: "",
        component: Mfa,
    },
    {
        path: "/reset",
        name: "Reset",
        icon: "",
        component: Reset,
    },
    {
        path: "/terms",
        name: "Terms",
        icon: "",
        component: Terms,
        footerOnly: true,
    },
    {
        path: "/privacy",
        name: "Privacy",
        icon: "",
        component: Privacy,
        footerOnly: true,
    },
    {
        redirect: true,
        path: "/",
        pathTo: "/home",
        name: "Home",
    },
];

export default routes;