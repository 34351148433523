import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { NavLink } from "react-router-dom";
import { login, ssoLogin, mfaCheck, validUser } from "../../actions/loginActions";
import { checkout } from "../../actions/registerActions";
import Mfa from './Mfa'




function Copyright() {

  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Muir Cyber Solutions
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(20),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function Login() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { register, handleSubmit, watch, errors } = useForm();
  const auth = useSelector((state) => state.login);
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [mfaEnbaled, setMfaEnabled] = useState(false)

  const onClick = () => {
    window.location = "/home";
    /*
  if(totpCheck(event)) {
    dispatch(login(data)).then(auth => {
    if(auth.sessionId) {
        checkout(auth.plan, auth.sessionId);
        }
      });
    }
    else  {
    return (
      <span>{auth.error ? "Authentication failed!" : "" }&nbsp;</span>
    )
    }
    */
  }




  const onSubmit = async data => {
    // This is the in progress MFA section.
    validUser({ username: data.username, password: data.password, mfa: false }).then(result => {

      // Follow normal login check if they are not a valid user.
      if (result.data == false) {
        dispatch(login(data)).then(auth => {
          if (auth.sessionId) {
            checkout(auth.plan, auth.sessionId);
          }
        });
      }

      else {
        // Check if mfa is enabled and cause rerender to switch to mfa component
        mfaCheck(data.username).then(val => {
          if (val.status == true) {
            setUsername(data.username)
            setPassword(data.password)
            setMfaEnabled(true)
          }
          else if (val.status == false) {
            dispatch(login(data)).then(auth => {
              if (auth.sessionId) {
                checkout(auth.plan, auth.sessionId);
              }
            });
          }

        });
      }
    });

  }

  const redirect = () => {
    dispatch(ssoLogin());
  }

  if (username != "" && password != "" && mfaEnbaled == true) {
    return (
      <Mfa
        username={username}
        password={password}
        useDispatch={useDispatch}
        useSelector={useSelector}
      />)
  }

  let msg = ''

  if (auth.email_error) {
    msg = "The provided email was not found."
  }
  else if (auth.password_error) {
    msg = "The provided password is incorrect."
  }
  else if (auth.active_error) {
    msg = 'Incorrect credientials. Please try again'
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)} >
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email address"
            name="username"
            autoComplete="email"
            autoFocus
            inputProps={{ ref: register }}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            inputProps={{ ref: register }}
          />
          {msg != '' &&
            <span style={{ display: 'block' }}>{msg}&nbsp;</span>
          }
          <Link component={NavLink} to="/reset" variant="body2">
            {"Forgot password?"}
          </Link>
          {false && <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <div text-align="center">
            <input type="image" src={'https://docs.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_signin_light.svg'} border="0" altText="Sign in with Microsoft" onClick={() => redirect()} />
          </div>
          <Grid container>
            <Grid item>
              <Link component={NavLink} style={{ paddingTop: "20px" }} to="/register" variant="body2">
                {"Don't have an account? Sign up"}
              </Link>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
}
