import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { startResetPassword, resetPassword } from '../../actions/loginActions';
//import { useSearchParams } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function Reset() {
  const { register, handleSubmit, watch, errors } = useForm();
  const classes = useStyles();
  const plan = useSelector((state) => state.register.plan);
  const history = useHistory()
  const location = useLocation()

  const handleFinishReset = (pass1, pass2) => {
    let user_id = location.state.user_id
    if (pass1 != pass2) {
      alert('Please make sure your passwords match')
      return
    }

    resetPassword({ password: pass1, user_id: user_id })
  }

  const handleStartReset = (data) => {
    data['request_type'] = 'password_reset'
    startResetPassword(data).then((data) => {
      alert(data)
    })
  }

  const onSubmit = async (data) => {
    if (data['initial_password'] && data['second_password']) {
      handleFinishReset(data['initial_password'], data['second_password'])
    }

    else {
      handleStartReset(data)
    }
  }

  // For the end of the reset flow when the user has recieved the redirect link.
  if (location.state) {
    if (location.state.user_id) {
      return (
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Typography component="h1" variant="h5">
              Reset password
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)} >
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="initial_password"
                label="Please enter new password"
                type='password'
                name="initial_password"
                autoComplete="Please enter your new password"
                autoFocus
                inputProps={{
                  ref: register,
                  pattern: '((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,40})',
                  title: "Include uppercase, lowercase, numbers, and special characters"
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="second_password"
                type='password'
                label="Please retype your password"
                name="second_password"
                autoComplete="Please enter your new password"
                inputProps={{
                  ref: register,
                  pattern: '((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).{8,40})',
                  title: "Include uppercase, lowercase, numbers, and special characters"
                }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Submit
              </Button>
            </form>
          </div>
        </Container>
      )
    }
  }

  // For the start of the reset flow when a user enters the email associated with their account.
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Reset password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" required fullWidth>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="Email"
              label="Email"
              name="Email"
              autoComplete="Email"
              inputProps={{ ref: register }}
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Reset password
          </Button>
        </form>
      </div>
    </Container>
  );
}