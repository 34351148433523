import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { NavLink } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer.js";
import { signUp, checkout, checkCode, createUser, createUserSuccess } from "../../actions/registerActions";
import { login, ssoLogin } from "../../actions/loginActions";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
export default function Register() {
  const { register, handleSubmit, watch, errors } = useForm();
  const dispatch = useDispatch();
  const classes = useStyles();
  const plan = useSelector((state) => state.register.plan);
  const [selectedPlan, setSelectedPlan] = useState(plan ? plan : "Basic");


  const onSubmit = async data => {
    // Is this secure
    let val = await checkCode({ "code": data.company, "sso": true });
    if (val.data == true) {
      window.location = '/app'
    }
    else {
      alert('An issue occured. Please try again.')
    }
    //window.location = val.request.responseURL;



    // { this.state.flag === 2 && <p>Your login credentials could not be verified, please try again.</p>}

  }



  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedPlan(val);
  }

  const onClick = () => {
    window.location = "/register"
  }

  /*
  const onSubmit = async data => {
    // Is this secure
    let val = await checkCode({"code": data.company});
    if(val.data == true) {  
      createUser(data); 
      dispatch(login(data)).then(auth => {
      if(auth.sessionId) {
        checkout(auth.plan, auth.sessionId);
        }
      });
    }
  }
  */

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5">
          Register
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl variant="outlined" required fullWidth>
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              required
              fullWidth
              id="company code"
              label="Company Code"
              name="company"
              autoComplete="company code"
              inputProps={{ ref: register }}
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Join company
          </Button>
          <Button
            onClick={() => onClick()}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Don't have a code? Go to Checkout
          </Button>
        </form>
      </div>
    </Container>
  );
}